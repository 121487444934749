import httpUtil from "@/utils/httpUtil";

// 获取登录信息
export const getTkbApiCallTicket = (params) => {
  const headers = {
    "Content-Type": "application/json", // 设置Content-Type请求头为application/json
  };
  return httpUtil.get("/api/tkbApi/call-ticket/tkbList", params, {
    headers,
  });
};

//获取登录地址
export const getCallTkbLogin = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.get("/api/tkbApi/call-ticket/tkbLogin", params, {
    headers,
  });
};

// 数据线索、 获取列表数据------------------------------------------------------
export const getCueData = (params) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return httpUtil.post("/api/tkbApi/data-trail/qryDataTrail", params, {
    headers,
  });
};

//转为客户
export const toCustomer = (params) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return httpUtil.post("/api/tkbApi/data-trail/toCustomer", params, {
    headers,
  });
};

//数据线索 获取模板数据
export const getTemplateList = (params) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return httpUtil.post("/api/crmApi/customer/getTemplateList", params, {
    headers,
  });
};

//数据线索 点呼
export const pointCalling = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/crmApi/customer/pointCalling", params, {
    headers,
  });
};

//数据线索 创建任务
export const createCustomerTask = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/crmApi/customer/createCustomerTask", params, {
    headers,
  });
};

//数据线索 删除任务
// http://47.106.197.60:8081/api/tkbApi/data-trail/delDataTrail
export const delDataTrail = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/tkbApi/data-trail/delDataTrail", params, {
    headers,
  });
};
// http://47.106.197.60:8081/api/tkbApi/data-trail/importDataTrail
export const importDataTrail = (params, type) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/tkbApi/data-trail/importDataTrail", params, type);
};

// 任务列表-------------------------------------------------------------------------------------------------------------------
// 5.1.5查询客户任务列表
export const qryCustomerTask = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/crmApi/customer-task/qryCustomerTask", params, {
    headers,
  });
};

// 5.1.2开启或暂停任务
export const openOfStopTask = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/crmApi/customer-task/openOfStopTask", params, {
    headers,
  });
};

// 删除任务
export const deleteTask = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/crmApi/customer-task/deleteTask", params, {
    headers,
  });
};

// 话单记录-----------------------------------------------------------
// http://47.106.197.60:8081/api/tkbApi/call-ticket/queryCallTicket
export const queryCallTicket = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/tkbApi/call-ticket/queryCallTicket", params, {
    headers,
  });
};

// 企业管理-----------------------------------------------------

// 5.1.7查询企业成员

export const aiQueryEmployee = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/crmPc/department/queryEmployee", params, {
    headers,
  });
};

// 5.1.8编辑企业成员
export const aiUpdateEmployee = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/crmPc/department/updateEmployee", params, {
    headers,
  });
};

// 5.1.9获取企业信息
export const aiGetCompany = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/crmPc/companyGoods/getCompany", params, {
    headers,
  });
};

// 5.1.11编辑企业信息
// http://127.0.0.1:8764/crmPc/companyGoods/updCompany
// export function aiUpdCompany(params, type) {

//   return httpUtil.post(
//     "/api/crmPc/companyGoods/updCompany",
//     params,
//     type
//   );
// }

export const aiUpdCompany = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpUtil.post("/api/crmPc/companyGoods/updCompany", params, {
    headers,
  });
};

// 登录AI话术
export const loginMem = (params) => {
  const headers = {
    "Content-Type": "multipart/form-data", // 设置Content-Type请求头为multipart/form-data
  };
  return httpUtil.get("/api/tkbApi/call-ticket/loginMem", params, {
    headers,
  });
};
