<template>
  <div>
    <span style="margin-right: 10px;">任务列表</span>
    <!-- <el-button type="primary" @click="getQryCustomerTask">刷新状态</el-button> -->
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="" label="序号" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="taskName" label="任务名称" width="120">
        </el-table-column>
        <el-table-column
          prop="address"
          label="任务进度"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <el-progress
              :text-inside="true"
              :stroke-width="32"
              :percentage="
                scope.row.count == 0
                  ? 0
                  : (scope.row.callNumber / scope.row.count) * 100
              "
            ></el-progress>
          </template>
        </el-table-column>
        <el-table-column prop="templateName" label="模板" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="count" label="总数量" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="success" label="成功条数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="fail" label="失败条数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="inputDate" label="接通率" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- {{ ((scope.row.success / scope.row.callNumber) * 100).toFixed(2) }}% -->
            <span v-if="scope.row.callNumber !== 0"
              >{{
                ((scope.row.success / scope.row.callNumber) * 100).toFixed(2)
              }}%</span
            >
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="callInterval"
          label="呼叫间隔"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="任务状态"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.taskStatus"
              active-color="#13ce66"
              @change="changeSwitch(scope.row, B)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作"
          show-overflow-tooltip
          align="left"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="changeSwitch(scope.row, 'A')"
              type="text"
              >开启</el-button
            >
            <el-button
              size="mini"
              @click="changeSwitch(scope.row, 'B')"
              type="text"
              >暂停</el-button
            >
            <el-button
              size="mini"
              @click="clickDeleteTask(scope.$index, scope.row)"
              type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="float: right;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20]"
        background
        :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- <div style="margin-top: 20px">
        <el-button @click="toggleSelection([tableData[1], tableData[2]])"
          >切换第二、第三行的选中状态</el-button
        >
        <el-button @click="toggleSelection()">取消选择</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { qryCustomerTask, openOfStopTask, deleteTask } from "@/api/aIMarketing";
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      total: 1,
      pageNum: 1,
      pageSize: 10,
    };
  },
  created() {
    this.getQryCustomerTask();
  },
  methods: {
    async getQryCustomerTask() {
      const res = await qryCustomerTask({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      this.tableData = res.data.pageInfo.list;
      this.total = res.data.pageInfo.total;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    changeSwitch(row, tick) {
      if (tick === "A") {
        openOfStopTask({
          customerTaskId: row.customerTaskId,
          taskId: row.taskId,
          taskStatus: 1,
        })
          .then((res) => {
            if (res.code == 200) {
              if (row.taskStatus) {
                this.$message.success("开启任务成功！");
              }
            }
          })
          .catch((err) => {
            if (err.code == 201) {
              console.log(err);
              this.$message.error(err.message);
            }
          });
      } else if (tick === "B") {
        openOfStopTask({
          customerTaskId: row.customerTaskId,
          taskId: row.taskId,
          taskStatus: 0,
        })
          .then((res) => {
            if (res.code == 200) {
              this.getQryCustomerTask();
              if (row.taskStatus) {
                this.$message.success("关闭任务成功！");
              }
            }
          })
          .catch((err) => {
            if (err.code == 201) {
              console.log(err);
              this.$message.error(err.message);
            }
          });
      } else {
        openOfStopTask({
          customerTaskId: row.customerTaskId,
          taskId: row.taskId,
          taskStatus: row.taskStatus ? 1 : 0,
        })
          .then((res) => {
            if (res.code == 200) {
              if (row.taskStatus) {
                this.$message.success("开启任务成功！");
              } else {
                this.$message.success("关闭任务");
              }
            }
          })
          .catch((err) => {
            if (err.code == 201) {
              console.log(err);
              this.$message.error(err.message);
            }
          });
      }
    },
    clickDeleteTask(index, row) {
      console.log({
        customerTaskId: row.customerTaskId,
        taskId: Number(row.taskId),
      });
      deleteTask({
        customerTaskId: row.customerTaskId,
        taskId: Number(row.taskId),
      })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除任务");
            this.tableData.splice(index, 1);
          }
        })
        .catch((err) => {
          if (err.code == 201) {
            console.log(err);
            this.$message.error(err.message);
          }
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getQryCustomerTask();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getQryCustomerTask();
    },
  },
};
</script>
<style lang="scss" scoped></style>
